import React, { lazy, Suspense } from 'react'
import { Route, Redirect, Switch } from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router'
import { CSSTransition, SwitchTransition } from 'react-transition-group'
import { connect } from 'react-redux'

import Layout from 'layouts'

const routes = [
  // Dashboards
  {
    path: '/dashboard/alpha',
    Component: lazy(() => import('pages/dashboard/alpha')),
    exact: true,
  },
  {
    path: '/dashboard/beta',
    Component: lazy(() => import('pages/dashboard/beta')),
    exact: true,
  },
  {
    path: '/dashboard/gamma',
    Component: lazy(() => import('pages/dashboard/gamma')),
    exact: true,
  },
  {
    path: '/dashboard/crypto',
    Component: lazy(() => import('pages/dashboard/crypto')),
    exact: true,
  },

  // Ecommerce
  {
    path: '/ecommerce/dashboard',
    Component: lazy(() => import('pages/ecommerce/dashboard')),
    exact: true,
  },
  {
    path: '/ecommerce/orders',
    Component: lazy(() => import('pages/ecommerce/orders')),
    exact: true,
  },
  {
    path: '/ecommerce/product-catalog',
    Component: lazy(() => import('pages/ecommerce/product-catalog')),
    exact: true,
  },
  {
    path: '/ecommerce/product-details',
    Component: lazy(() => import('pages/ecommerce/product-details')),
    exact: true,
  },
  {
    path: '/ecommerce/cart',
    Component: lazy(() => import('pages/ecommerce/cart')),
    exact: true,
  },

  // Apps
  {
    path: '/apps/messaging',
    Component: lazy(() => import('pages/apps/messaging')),
    exact: true,
  },
  {
    path: '/apps/calendar',
    Component: lazy(() => import('pages/apps/calendar')),
    exact: true,
  },
  {
    path: '/apps/mail',
    Component: lazy(() => import('pages/apps/mail')),
    exact: true,
  },
  {
    path: '/apps/profile',
    Component: lazy(() => import('pages/apps/profile')),
    exact: true,
  },
  {
    path: '/apps/gallery',
    Component: lazy(() => import('pages/apps/gallery')),
    exact: true,
  },
  // Extra Apps
  {
    path: '/apps/github-explore',
    Component: lazy(() => import('pages/apps/github-explore')),
    exact: true,
  },
  {
    path: '/apps/github-discuss',
    Component: lazy(() => import('pages/apps/github-discuss')),
    exact: true,
  },
  {
    path: '/apps/digitalocean-droplets',
    Component: lazy(() => import('pages/apps/digitalocean-droplets')),
    exact: true,
  },
  {
    path: '/apps/digitalocean-create',
    Component: lazy(() => import('pages/apps/digitalocean-create')),
    exact: true,
  },
  {
    path: '/apps/google-analytics',
    Component: lazy(() => import('pages/apps/google-analytics')),
    exact: true,
  },
  {
    path: '/apps/wordpress-post',
    Component: lazy(() => import('pages/apps/wordpress-post')),
    exact: true,
  },
  {
    path: '/apps/wordpress-posts',
    Component: lazy(() => import('pages/apps/wordpress-posts')),
    exact: true,
  },
  {
    path: '/apps/wordpress-add',
    Component: lazy(() => import('pages/apps/wordpress-add')),
    exact: true,
  },
  {
    path: '/apps/todoist-list',
    Component: lazy(() => import('pages/apps/todoist-list')),
    exact: true,
  },
  {
    path: '/apps/jira-dashboard',
    Component: lazy(() => import('pages/apps/jira-dashboard')),
    exact: true,
  },
  {
    path: '/apps/jira-agile-board',
    Component: lazy(() => import('pages/apps/jira-agile-board')),
    exact: true,
  },
  {
    path: '/apps/helpdesk-dashboard',
    Component: lazy(() => import('pages/apps/helpdesk-dashboard')),
    exact: true,
  },
  // Widgets
  {
    path: '/widgets/general',
    Component: lazy(() => import('pages/widgets/general')),
    exact: true,
  },
  {
    path: '/widgets/lists',
    Component: lazy(() => import('pages/widgets/lists')),
    exact: true,
  },
  {
    path: '/widgets/tables',
    Component: lazy(() => import('pages/widgets/tables')),
    exact: true,
  },
  {
    path: '/widgets/charts',
    Component: lazy(() => import('pages/widgets/charts')),
    exact: true,
  },
  // Cards
  {
    path: '/cards/basic-cards',
    Component: lazy(() => import('pages/cards/basic-cards')),
    exact: true,
  },
  {
    path: '/cards/tabbed-cards',
    Component: lazy(() => import('pages/cards/tabbed-cards')),
    exact: true,
  },
  // UI Kits
  {
    path: '/ui-kits/bootstrap',
    Component: lazy(() => import('pages/ui-kits/bootstrap')),
    exact: true,
  },
  {
    path: '/ui-kits/antd',
    Component: lazy(() => import('pages/ui-kits/antd')),
    exact: true,
  },
  // Tables
  {
    path: '/tables/bootstrap',
    Component: lazy(() => import('pages/tables/bootstrap')),
    exact: true,
  },
  {
    path: '/tables/antd',
    Component: lazy(() => import('pages/tables/antd')),
    exact: true,
  },
  // Charts
  {
    path: '/charts/chartistjs',
    Component: lazy(() => import('pages/charts/chartistjs')),
    exact: true,
  },
  {
    path: '/charts/chartjs',
    Component: lazy(() => import('pages/charts/chartjs')),
    exact: true,
  },
  {
    path: '/charts/c3',
    Component: lazy(() => import('pages/charts/c3')),
    exact: true,
  },
  // Icons
  {
    path: '/icons/feather-icons',
    Component: lazy(() => import('pages/icons/feather-icons')),
    exact: true,
  },
  {
    path: '/icons/fontawesome',
    Component: lazy(() => import('pages/icons/fontawesome')),
    exact: true,
  },
  {
    path: '/icons/linearicons-free',
    Component: lazy(() => import('pages/icons/linearicons-free')),
    exact: true,
  },
  {
    path: '/icons/icomoon-free',
    Component: lazy(() => import('pages/icons/icomoon-free')),
    exact: true,
  },
  // Advanced
  {
    path: '/advanced/form-examples',
    Component: lazy(() => import('pages/advanced/form-examples')),
    exact: true,
  },
  {
    path: '/advanced/email-templates',
    Component: lazy(() => import('pages/advanced/email-templates')),
    exact: true,
  },
  {
    path: '/advanced/utilities',
    Component: lazy(() => import('pages/advanced/utilities')),
    exact: true,
  },
  {
    path: '/advanced/grid',
    Component: lazy(() => import('pages/advanced/grid')),
    exact: true,
  },
  {
    path: '/advanced/typography',
    Component: lazy(() => import('pages/advanced/typography')),
    exact: true,
  },
  {
    path: '/advanced/pricing-tables',
    Component: lazy(() => import('pages/advanced/pricing-tables')),
    exact: true,
  },
  {
    path: '/advanced/invoice',
    Component: lazy(() => import('pages/advanced/invoice')),
    exact: true,
  },
  {
    path: '/advanced/colors',
    Component: lazy(() => import('pages/advanced/colors')),
    exact: true,
  },
  // Store-Settings
  // sms
  {
    path: '/storesettings/sms',
    Component: lazy(() => import('pages/StoreSettings/Sms/index')),
    exact: true,
  },
  {
    path: '/storesettings/mails',
    Component: lazy(() => import('pages/StoreSettings/Mails/index')),
    exact: true,
  },
  {
    path: '/storesettings/razorpaysettings',
    Component: lazy(() => import('pages/StoreSettings/RazorpaySettings/index')),
    exact: true,
  },
  {
    path: '/storesettings/subscription',
    Component: lazy(() => import('pages/StoreSettings/Subscription/index')),
    exact: true,
  },
  // Store-Frontend
  // Pagess
  {
    path: '/storefrontend/pagess',
    Component: lazy(() => import('pages/StoreFrontEnd/Pages/Pagess')),
    exact: true,
  },
  {
    path: '/storefrontend/table',
    Component: lazy(() => import('pages/StoreFrontEnd/Pages/Table')),
    exact: true,
  },
  // HomeSlide
  {
    path: '/storefrontend/homeslide',
    Component: lazy(() => import('pages/StoreFrontEnd/HomeSlide/AddHomeSlide')),
    exact: true,
  },
  {
    path: '/storefrontend/homeslidetable',
    Component: lazy(() => import('pages/StoreFrontEnd/HomeSlide/HomeSlideTable')),
    exact: true,
  },
  //OfferSlide
  {
    path: '/storefrontend/offerslide',
    Component: lazy(() => import('pages/StoreFrontEnd/OfferSlide/AddOfferSlide')),
    exact: true,
  },
  {
    path: '/storefrontend/offerslidetable',
    Component: lazy(() => import('pages/StoreFrontEnd/OfferSlide/OfferSlideTable')),
    exact: true,
  },
  //Image Gallery
  {
    path: '/storefrontend/imggallery',
    Component: lazy(() => import('pages/StoreFrontEnd/Gallery/AddImage')),
    exact: true,
  },
  {
    path: '/storefrontend/imggallerytable',
    Component: lazy(() => import('pages/StoreFrontEnd/Gallery/ImageTable')),
    exact: true,
  },
  {
    path: '/storefrontend/testimonials',
    Component: lazy(() => import('pages/StoreFrontEnd/Testimonial')),
    exact: true,
  },
  {
    path: '/storefrontend/contact',
    Component: lazy(() => import('pages/StoreFrontEnd/Contacts')),
    exact: true,
  },
  //Vendor
  {
    path: '/vendor/type',
    Component: lazy(() => import('pages/Vendor/Type')),
    exact: true,
  },
  {
    path: '/vendor/viewVendors',
    Component: lazy(() => import('pages/Vendor/VendorName/index')),
    exact: true,
  },
  {
    path: '/vendor/addVendors',
    Component: lazy(() => import('pages/Vendor/VendorName/addVendor')),
    exact: true,
  },
  {
    path: `/vendor/vendorhour/:Id`,
    Component: lazy(() => import('pages/Vendor/vendorhour')),
    exact: true,
  },
  {
    path: `/bankdetails/:Id`,
    Component: lazy(() => import('pages/Vendor/Bank')),
    exact: true,
  },
  //Service
  {
    path: '/service/type',
    Component: lazy(() => import('pages/Service/Type')),
    exact: true,
  },
  {
    path: '/service/services',
    Component: lazy(() => import('pages/Service/servicename')),
    exact: true,
  },
  {
    path: '/service/serviceproduct/:Id',
    Component: lazy(() => import('pages/Service/serviceproducts')),
    exact: true,
  },
  {
    path: '/service/servicehour/:Id',
    Component: lazy(() => import('pages/Service/ServiceHour')),
    exact: true,
  },
  //Doctor
  {
    path: '/doctor/type',
    Component: lazy(() => import('pages/Doctor/Type')),
    exact: true,
  },
  {
    path: '/doctor/doctor',
    Component: lazy(() => import('pages/Doctor/doctorname')),
    exact: true,
  },
  {
    path: '/doctor/servicehour/:Id',
    Component: lazy(() => import('pages/Doctor/ServiceHour')),
    exact: true,
  },

  //Delivery Partner
  {
    path: '/partner',
    Component: lazy(() => import('pages/deliverypartner')),
    exact: true,
  },

  //User
  //User Table
  {
    path: '/user/userinfo',
    Component: lazy(() => import('pages/User/UserTable')),
    exact: true,
  },
  //Edit User
  {
    path: '/user/useredit',
    Component: lazy(() => import('pages/User/EditUser')),
    exact: true,
  },
  {
    path: '/user/profile',
    Component: lazy(() => import('pages/User/Profile')),
    exact: true,
  },
  // Auth Pages
  {
    path: '/auth/login',
    Component: lazy(() => import('pages/auth/login')),
    exact: true,
  },
  {
    path: '/auth/forgot-password',
    Component: lazy(() => import('pages/auth/forgot-password')),
    exact: true,
  },
  {
    path: '/auth/register',
    Component: lazy(() => import('pages/auth/register')),
    exact: true,
  },
  {
    path: '/auth/lockscreen',
    Component: lazy(() => import('pages/auth/lockscreen')),
    exact: true,
  },
  {
    path: '/auth/404',
    Component: lazy(() => import('pages/auth/404')),
    exact: true,
  },
  {
    path: '/auth/500',
    Component: lazy(() => import('pages/auth/500')),
    exact: true,
  },
  // Appointment
  {
    path: '/doctor/appointment',
    Component: lazy(() => import('pages/appointment/doc')),
    exact: true,
  },
  {
    path: '/service/appointment',
    Component: lazy(() => import('pages/appointment/service')),
    exact: true,
  },

  // Order
  {
    path: '/order/order',
    Component: lazy(() => import('pages/order/index')),
    exact: true,
  },
  // Store
  // {
  //   path: '/store/company',
  //   Component: lazy(() => import('pages/store/company')),
  //   exact: true,
  // },
  {
    path: '/store/city',
    Component: lazy(() => import('pages/store/city')),
    exact: true,
  },
  {
    path: '/store/paymentmode',
    Component: lazy(() => import('pages/store/paymentmodes')),
    exact: true,
  },
  // {
  //   path: '/store/branch',
  //   Component: lazy(() => import('pages/store/branch')),
  //   exact: true,
  // },
  // Store Management
  {
    path: '/storemanagement/attributecat',
    Component: lazy(() => import('pages/store_management/attributecat')),
    exact: true,
  },
  {
    path: '/storemanagement/category',
    Component: lazy(() => import('pages/store_management/category')),
    exact: true,
  },
  {
    path: '/storemanagement/category/:VId',
    Component: lazy(() => import('pages/store_management/category')),
    exact: true,
  },
  {
    path: '/storemanagement/subcategory/:VId/:Id',
    Component: lazy(() => import('pages/store_management/subcategory')),
    exact: true,
  },
  {
    path: `/storemanagement/productvar/:varId/:VId`,
    Component: lazy(() => import('pages/store_management/productvariant')),
    exact: true,
  },
  {
    path: `/storemanagement/todaydeal`,
    Component: lazy(() => import('pages/store_management/todaydeal')),
    exact: true,
  },
  {
    path: `/storemanagement/weeklydeal`,
    Component: lazy(() => import('pages/store_management/weeklydeal')),
    exact: true,
  },
  // Attribute Type Name
  {
    path: '/storemanagement/attributetype/:Id',
    Component: lazy(() => import('pages/store_management/attributetype/AddPags')),
    exact: true,
  },
  {
    path: '/storemanagement/attributetypetable/:Id',
    Component: lazy(() => import('pages/store_management/attributetype/AttributeTypeTable')),
    exact: true,
  },
  //Brand
  {
    path: '/storemanagement/brand',
    Component: lazy(() => import('pages/store_management/Brand/AddBrand')),
    exact: true,
  },
  {
    path: '/storemanagement/brandtable',
    Component: lazy(() => import('pages/store_management/Brand/BrandTable')),
    exact: true,
  },
  //Product
  {
    path: '/storemanagement/product/:VId',
    Component: lazy(() => import('pages/store_management/Product/AddProduct')),
    exact: true,
  },
  {
    path: '/storemanagement/product/:VId/:PId',
    Component: lazy(() => import('pages/store_management/Product/AddProduct')),
    exact: true,
  },
  {
    path: '/storemanagement/product/:VId/:CId',
    Component: lazy(() => import('pages/store_management/Product/AddProduct')),
    exact: true,
  },
  {
    path: '/storemanagement/producttable',
    Component: lazy(() => import('pages/store_management/Product/ProductTable')),
    exact: true,
  },
  {
    path: '/storemanagement/producttable/:VId',
    Component: lazy(() => import('pages/store_management/Product/ProductTable')),
    exact: true,
  },
  {
    path: '/storemanagement/producttable/:VId/:CId',
    Component: lazy(() => import('pages/store_management/Product/ProductTable')),
    exact: true,
  },
  //Store Cart
  {
    path: '/store_cart/promocode',
    Component: lazy(() => import('pages/store_cart/promocode')),
    exact: true,
  },
]

const mapStateToProps = ({ settings }) => ({
  routerAnimation: settings.routerAnimation,
})

const Router = ({ history, routerAnimation }) => {
  return (
    <ConnectedRouter history={history}>
      <Layout>
        <Route
          render={state => {
            const { location } = state
            return (
              <SwitchTransition>
                <CSSTransition
                  key={location.pathname}
                  appear
                  classNames={routerAnimation}
                  timeout={routerAnimation === 'none' ? 0 : 300}
                >
                  <Switch location={location}>
                    <Route exact path="/" render={() => <Redirect to="/store/city" />} />
                    {routes.map(({ path, Component, exact }) => (
                      <Route
                        path={path}
                        key={path}
                        exact={exact}
                        render={() => {
                          return (
                            <div className={routerAnimation}>
                              <Suspense fallback={null}>
                                <Component />
                              </Suspense>
                            </div>
                          )
                        }}
                      />
                    ))}
                    <Redirect to="/auth/404" />
                  </Switch>
                </CSSTransition>
              </SwitchTransition>
            )
          }}
        />
      </Layout>
    </ConnectedRouter>
  )
}

export default connect(mapStateToProps)(Router)
