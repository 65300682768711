export default async function getMenuData() {
  return [
    // {
    //   category: true,
    //   title: 'Dashboards',
    // },
    // {
    //   title: 'Dashboards',
    //   key: 'dashboards',
    //   icon: 'fe fe-home',
    //   roles: ['admin'], // set user roles with access to this route
    //   count: 4,
    //   children: [
    //     {
    //       title: 'Dashboard Alpha',
    //       key: 'dashboard',
    //       url: '/dashboard/alpha',
    //     },
    //     {
    //       title: 'Dashboard Beta',
    //       key: 'dashboardBeta',
    //       url: '/dashboard/beta',
    //     },
    //     {
    //       title: 'Dashboard Gamma',
    //       key: 'dashboardGamma',
    //       url: '/dashboard/gamma',
    //     },
    //     {
    //       title: 'Crypto Terminal',
    //       key: 'dashboardCrypto',
    //       url: '/dashboard/crypto',
    //     },
    //   ],
    // },
    // {
    //   category: true,
    //   title: 'Apps & Pages',
    // },
    /*{
      title: 'Apps',
      key: 'apps',
      icon: 'fe fe-database',
      children: [
        {
          title: 'Profile',
          key: 'appsProfile',
          url: '/apps/profile',
        },
        {
          title: 'Calendar',
          key: 'appsCalendar',
          url: '/apps/calendar',
        },
        {
          title: 'Gallery',
          key: 'appsGallery',
          url: '/apps/gallery',
        },
        {
          title: 'Messaging',
          key: 'appsCart',
          url: '/apps/messaging',
        },
        {
          title: 'Mail',
          key: 'appsMail',
          url: '/apps/mail',
        },
      ],
    },
    {
      category: true,
      title: 'Store Settings',
    },
    {
      title: 'Sms',
      key: 'sms',
      icon: 'fe fe-database',
      url: '/storesettings/sms',
    },
    {
      title: 'Mails',
      key: 'mails',
      icon: 'fe fe-database',
      url: '/storesettings/mails',
    },
    {
      title: 'Razorpay Settings',
      key: 'razorpaysettings',
      icon: 'fe fe-database',
      url: '/storesettings/razorpaysettings',
    },
    {
      title: 'Subscription',
      key: 'subscription',
      icon: 'fe fe-database',
      url: '/storesettings/subscription',
    },
    {
      title: 'Extra Apps',
      key: 'extraApps',
      icon: 'fe fe-hard-drive',
      children: [
        {
          title: 'Github Explore',
          key: 'extraAppsGithubExplore',
          url: '/apps/github-explore',
        },
        {
          title: 'Github Discuss',
          key: 'extraAppsGithubDiscuss',
          url: '/apps/github-discuss',
        },
        {
          title: 'Digitalocean Droplets',
          key: 'extraAppsDigitaloceanDroplets',
          url: '/apps/digitalocean-droplets',
        },
        {
          title: 'Digitalocean Create',
          key: 'extraAppsDigitaloceanCreate',
          url: '/apps/digitalocean-create',
        },
        {
          title: 'Google Analytics',
          key: 'extraAppsGoogleAnalytis',
          url: '/apps/google-analytics',
        },
        {
          title: 'Wordpress Post',
          key: 'extraAppsWordpressPost',
          url: '/apps/wordpress-post',
        },
        {
          title: 'Wordpress Posts',
          key: 'extraAppsWordpressPosts',
          url: '/apps/wordpress-posts',
        },
        {
          title: 'Wordpress Add',
          key: 'extraAppsWordpressAdd',
          url: '/apps/wordpress-add',
        },
        {
          title: 'Todoist List',
          key: 'extraAppsTodoistList',
          url: '/apps/todoist-list',
        },
        {
          title: 'Jira Dashboard',
          key: 'extraAppsJiraDashboard',
          url: '/apps/jira-dashboard',
        },
        {
          title: 'Jira Agile Board',
          key: 'extraAppsJiraAgileBoard',
          url: '/apps/jira-agile-board',
        },
        {
          title: 'Helpdesk Dashboard',
          key: 'extraAppsHelpdeskDashboard',
          url: '/apps/helpdesk-dashboard',
        },
      ],
    },
    {
      title: 'Ecommerce',
      key: 'ecommerce',
      icon: 'fe fe-shopping-cart',
      children: [
        {
          title: 'Dashboard',
          key: 'ecommerceDashboard',
          url: '/ecommerce/dashboard',
        },
        {
          title: 'Orders',
          key: 'ecommerceOrders',
          url: '/ecommerce/orders',
        },
        {
          title: 'Propduct Catalog',
          key: 'ecommerceProductCatalog',
          url: '/ecommerce/product-catalog',
        },
        {
          title: 'Product Details',
          key: 'ecommerceProductDetails',
          url: '/ecommerce/product-details',
        },
        {
          title: 'Cart',
          key: 'ecommerceCart',
          url: '/ecommerce/cart',
        },
      ],
    },*/
    // {
    //   title: 'Auth Pages',
    //   key: 'auth',
    //   icon: 'fe fe-user',
    //   children: [
    //     {
    //       title: 'Login',
    //       key: 'authLogin',
    //       url: '/auth/login',
    //     },
    //     {
    //       title: 'Forgot Password',
    //       key: 'authForgotPassword',
    //       url: '/auth/forgot-password',
    //     },
    //     {
    //       title: 'Register',
    //       key: 'authRegister',
    //       url: '/auth/register',
    //     },
    //     {
    //       title: 'Lockscreen',
    //       key: 'authLockscreen',
    //       url: '/auth/lockscreen',
    //     },
    //     {
    //       title: 'Page 404',
    //       key: 'auth404',
    //       url: '/auth/404',
    //     },
    //     {
    //       title: 'Page 500',
    //       key: 'auth500',
    //       url: '/auth/500',
    //     },
    //   ],
    // },
    /*{
      category: true,
      title: 'UI Kits',
    },
    {
      title: 'Ant Design',
      key: 'antDesign',
      icon: 'fe fe-bookmark',
      url: '/ui-kits/antd',
    },
    {
      title: 'Bootstrap',
      key: 'bootstrap',
      icon: 'fe fe-bookmark',
      url: '/ui-kits/bootstrap',
    },*/
    //Vendor
    {
      category: true,
      title: 'Vendor',
    },
    {
      title: 'Vendor Type',
      key: 'typev',
      icon: 'fe fe-users',
      url: '/vendor/type',
    },
    {
      title: 'Vendors',
      key: 'typen',
      icon: 'fe fe-users',
      url: '/vendor/viewVendors',
    },
    //Service
    {
      category: true,
      title: 'Service',
    },
    {
      title: 'Service Type',
      key: 'servicet',
      icon: 'fe fe-user-check',
      url: '/service/type',
    },
    {
      title: 'Service',
      key: 'servicen',
      icon: 'fe fe-user-check',
      url: '/service/services',
    },
    {
      title: 'Service Appointment',
      key: 'serviceappointment',
      icon: 'fe fe-user-check',
      url: '/service/appointment',
    },
    //Doctor
    {
      category: true,
      title: 'Doctor',
    },
    {
      title: 'Doctor Type',
      key: 'doctype',
      icon: 'fe fe-user-check',
      url: '/doctor/type',
    },
    {
      title: 'Doctor',
      key: 'docdetails',
      icon: 'fe fe-user-check',
      url: '/doctor/doctor',
    },
    {
      title: 'Doctor Appointment',
      key: 'docappointment',
      icon: 'fe fe-user-check',
      url: '/doctor/appointment',
    },
    //partner
    {
      category: true,
      title: 'Delivery Partner',
    },
    {
      title: 'Delivery Partner',
      key: 'delivery',
      icon: 'fe fe-home',
      url: '/partner',
    },
    //partner
    {
      category: true,
      title: 'Order',
    },
    {
      title: 'Orders',
      key: 'order',
      icon: 'fe fe-database',
      url: '/order/order',
    },
    // Store Frontend
    {
      category: true,
      title: 'Store Frontend',
    },
    {
      title: 'Pages',
      key: 'pages',
      icon: 'fe fe-grid',
      url: '/storefrontend/table',
    },
    {
      title: 'Home Slide',
      key: 'homeslide',
      icon: 'fe fe-grid',
      url: '/storefrontend/homeslidetable',
    },
    {
      title: 'Offer Slide',
      key: 'offerslide',
      icon: 'fe fe-grid',
      url: '/storefrontend/offerslidetable',
    },
    {
      title: 'Image Gallery',
      key: 'imggallery',
      icon: 'fe fe-grid',
      url: '/storefrontend/imggallerytable',
    },
    {
      title: 'Testimonials',
      key: 'testimonials',
      icon: 'fe fe-grid',
      url: '/storefrontend/testimonials',
    },
    {
      title: 'Contacts',
      key: 'contact',
      icon: 'fe fe-grid',
      url: '/storefrontend/contact',
    },
    //User
    {
      category: true,
      title: 'User',
    },
    {
      title: 'User Info',
      key: 'userinfo',
      icon: 'fe fe-grid',
      url: '/user/userinfo',
    },
    {
      title: 'Edit Profile',
      key: 'useredit',
      icon: 'fe fe-grid',
      url: '/user/useredit',
    },
    // {
    //   title: 'Profile',
    //   key: 'profile',
    //   icon: 'fe fe-grid',
    //   url: '/user/profile',
    // },
    // Store
    {
      category: true,
      title: 'General',
    },
    // {
    //   title: 'Company',
    //   key: 'company',
    //   icon: 'fe fe-credit-card',
    //   url: '/store/company',
    // },
    {
      title: 'City',
      key: 'city',
      icon: 'fe fe-credit-card',
      url: '/store/city',
    },
    {
      title: 'Payment Mode',
      key: 'paymentmode',
      icon: 'fe fe-credit-card',
      url: '/store/paymentmode',
    },
    // {
    //   title: 'Branch',
    //   key: 'branch',
    //   icon: 'fe fe-credit-card',
    //   url: '/store/branch',
    // },
    // StoreManagement
    // {
    //   category: true,
    //   title: 'Store',
    // },
    // {
    //   title: 'Attribute Category',
    //   key: 'attributecat',
    //   icon: 'fe fe-credit-card',
    //   url: '/storemanagement/attributecat',
    // },
    // {
    //   title: 'Category',
    //   key: 'category',
    //   icon: 'fe fe-credit-card',
    //   url: '/storemanagement/category',
    // },
    // {
    //   title: 'Subcategory',
    //   key: 'subcategory',
    //   icon: 'fe fe-credit-card',
    //   url: '/storemanagement/subcategory',
    // },
    // {
    //   title: 'Attribute Type',
    //   key: 'attributetype',
    //   icon: 'fe fe-grid',
    //   url: '/storemanagement/attributetypetable',
    // },
    // {
    //   title: 'Brand',
    //   key: 'brand',
    //   icon: 'fe fe-grid',
    //   url: '/storemanagement/brandtable',
    // },
    // {
    //   title: 'Product',
    //   key: 'product',
    //   icon: 'fe fe-grid',
    //   url: '/storemanagement/producttable',
    // },
    // {
    //   category: true,
    //   title: 'Store cart',
    // },
    // {
    //   title: 'Promo Code',
    //   key: 'promocode',
    //   icon: 'fe fe-credit-card',
    //   url: '/store_cart/promocode',
    // },
    /*
      title: 'Today\'s Deal',
      key: 'todaydeal',
      icon: 'fe fe-grid',
      url: '/storemanagement/todaydeal',
    },
    {
      title: 'Weekly Deal',
      key: 'weeklydeal',
      icon: 'fe fe-grid',
      url: '/storemanagement/weeklydeal',
    },/*
    {
      category: true,
      title: 'Components',
    },
    {
      title: 'Widgets',
      key: 'widgets',
      icon: 'fe fe-image',
      count: 47,
      children: [
        {
          title: 'General',
          key: 'widgetsGeneral',
          url: '/widgets/general',
        },
        {
          title: 'Lists',
          key: 'widgetsLists',
          url: '/widgets/lists',
        },
        {
          title: 'Tables',
          key: 'widgetsTables',
          url: '/widgets/tables',
        },
        {
          title: 'Charts',
          key: 'widgetsCharts',
          url: '/widgets/charts',
        },
      ],
    },
    {
      title: 'Cards',
      key: 'cards',
      icon: 'fe fe-credit-card',
      children: [
        {
          title: 'Basic Cards',
          key: 'cardsBasicCards',
          url: '/cards/basic-cards',
        },
        {
          title: 'Tabbed Cards',
          key: 'cardsTabbedCards',
          url: '/cards/tabbed-cards',
        },
      ],
    },
    {
      title: 'Tables',
      key: 'tables',
      icon: 'fe fe-grid',
      children: [
        {
          title: 'Ant Design',
          key: 'tablesAntd',
          url: '/tables/antd',
        },
        {
          title: 'Bootstrap',
          key: 'tablesBootstrap',
          url: '/tables/bootstrap',
        },
      ],
    },
    {
      title: 'Charts',
      key: 'charts',
      icon: 'fe fe-pie-chart',
      children: [
        {
          title: 'Chartist.js',
          key: 'chartsChartistjs',
          url: '/charts/chartistjs',
        },
        {
          title: 'Chart.js',
          key: 'chartsChartjs',
          url: '/charts/chartjs',
        },
        {
          title: 'C3',
          key: 'chartsC3',
          url: '/charts/c3',
        },
      ],
    },
    {
      title: 'Icons',
      key: 'icons',
      icon: 'fe fe-star',
      children: [
        {
          title: 'Feather Icons',
          key: 'iconsFeatherIcons',
          url: '/icons/feather-icons',
        },
        {
          title: 'Fontawesome',
          key: 'iconsFontawesome',
          url: '/icons/fontawesome',
        },
        {
          title: 'Linearicons Free',
          key: 'iconsLineariconsFree',
          url: '/icons/linearicons-free',
        },
        {
          title: 'Icomoon Free',
          key: 'iconsIcomoonFree',
          url: '/icons/icomoon-free',
        },
      ],
    },
    {
      category: true,
      title: 'Advanced',
    },
    {
      title: 'Form Examples',
      key: 'formExamples',
      icon: 'fe fe-menu',
      url: '/advanced/form-examples',
    },
    {
      title: 'Email Templates',
      key: 'emailTemplates',
      icon: 'fe fe-mail',
      url: '/advanced/email-templates',
    },
    {
      title: 'Pricing Tables',
      key: 'pricingTables',
      icon: 'fe fe-command',
      url: '/advanced/pricing-tables',
    },
    {
      title: 'Invoice',
      key: 'invoice',
      icon: 'fe fe-file-text',
      url: '/advanced/invoice',
    },
    {
      title: 'Utilities',
      key: 'utilities',
      icon: 'fe fe-inbox',
      url: '/advanced/utilities',
    },
    {
      title: 'Grid',
      key: 'grid',
      icon: 'fe fe-grid',
      url: '/advanced/grid',
    },
    {
      title: 'Typography',
      key: 'typography',
      icon: 'fe fe-type',
      url: '/advanced/typography',
    },
    {
      title: 'Colors',
      key: 'colors',
      icon: 'fe fe-feather',
      url: '/advanced/colors',
    },
    {
      title: 'Promo Code',
      key: 'promocode',
      icon: 'TagOutlined',
      url: '/store_cart/promocode',
    },
    {
      title: 'Nested Items',
      key: 'nestedItem1',
      icon: 'fe fe-layers',
      disabled: true,
      children: [
        {
          title: 'Nested Item 1-1',
          key: 'nestedItem1-1',
          children: [
            {
              title: 'Nested Item 1-1-1',
              key: 'nestedItem1-1-1',
            },
            {
              title: 'Nested Items 1-1-2',
              key: 'nestedItem1-1-2',
              disabled: true,
            },
          ],
        },
        {
          title: 'Nested Items 1-2',
          key: 'nestedItem1-2',
        },
      ],
    },*/
    // {
    //   title: 'Disabled Item',
    //   key: 'disabledItem',
    //   icon: 'fe fe-slash',
    //   disabled: true,
    // },
  ]
}
